import React from 'react'
import Loadable from '@loadable/component'
import ModalVideo from 'react-modal-video'
import {Link} from 'gatsby';

const OwlCarousel = Loadable(() => import('react-owl-carousel3'))
const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const Banner = ({video, bannerDetail}) => {
    // Popup Video
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

     const [display, setDisplay] = React.useState(false);

     React.useEffect(() => {
         setDisplay(true);
     }, [])
 


return (
    <React.Fragment>
     <ModalVideo 
            channel='youtube' 
            isOpen={!isOpen} 
            videoId={video} 
            onClose={() => setIsOpen(!isOpen)} 
        />
{display ? <OwlCarousel 

    className="home-slides component-parent pb-1 edms-slider owl-carousel owl-theme"
    {...options}
> 
    
    {bannerDetail?.map((detail, index) => (
        <div key={index} className="edms-text-container">
            <div className="">
                <div className="container">
                    <div className="banner-item-content">
                        <p className='font-outfit banner-title'>{detail?.title}</p> 
                        <img className='edms-banner-img' src={detail?.image?.url || detail?.image} alt={detail?.image?.alternativeText || detail?.img_tag} />
                        <p>{detail?.description}</p>
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                            {video &&
                                <Link
                                    to="#play-video"
                                    onClick={e => { e.preventDefault(); openModal() }}
                                    className="video-btn popup-youtube"
                                >
                                    <i className="flaticon-google-play"></i> Watch Video
                                </Link> 
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ))}
 </OwlCarousel> : ''}
</React.Fragment>
)
}

export default Banner