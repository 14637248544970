import React from 'react'
import {Link} from 'gatsby'
import Pdf1 from "../../assets/Brochures/Functionalities.pdf"
import Pdf2 from "../../assets/Brochures/Panacea_Brochure.pdf"


const BrocherSidebar = () => {
    return(
        <div className='d-flex' >
            <div>
                <a href = {Pdf2} target = "_blank" className='default-btn'>View Brochure <i className='bx bxs-file-pdf'></i><span></span> </a>
            </div>
            <div>
                <a href = {Pdf1} target = "_blank" className='default-btn'>Panacea Functionalities <i className='bx bxs-file-pdf'></i><span></span> </a>
            </div>
        </div>
    )
    // return (
    //     // <div className="services-details-info">
    //     <div>
    //         <div className='d-flex align-items-baselin'>
    //             <div>
    //                 <a href = {Pdf2} target = "_blank" className='default-btn'>View Brochure <i className='bx bxs-file-pdf'></i><span></span> </a>
    //             </div>
    //             <div>
    //                 <a href = {Pdf1} target = "_blank" className='default-btn'>Functionalities <i className='bx bxs-file-pdf'></i><span></span> </a>
    //             </div>
    //         </div>

    //         {/* <div className="download-file">
    //             <h3>Brochures</h3>
    //             <ul>
    //             <li>
    //                 <a href = {Pdf2} target = "_blank">Panacea Brochure <i className='bx bxs-file-pdf'></i> </a>
    //                 </li>
    //                 <li>
    //                      <a href = {Pdf1} target = "_blank">Functionalities <i className='bx bxs-file-pdf'></i> </a>
    //                 </li>
    //             </ul>
    //         </div> */}

            
    //     </div>
    // )
}

export default BrocherSidebar