import React from 'react'
import ReactMarkdown from 'react-markdown'

const Details = ( data ) => {

    return (
        <section className="services-details-area isbt-container ptb-70">
            <div className="container">
                {data?.featureHeading && data?.featureDesc &&
                    <div className='intro-container my-5'>
                        <h2 className='font-outfit text-center heading-one'>{data?.featureHeading}</h2>
                        <p>{data?.featureDesc}</p>
                    </div>
                }
                
                <div className="row">
                    {data?.data?.featureImage ?
                        <>
                            <div className="col-lg-6 col-md-12">
                                <ReactMarkdown>{data?.data?.features?.data?.features}</ReactMarkdown>
                            </div>

                            <div className="col-lg-6 col-md-12 m-auto isbt-detail-image">
                                {
                                    data?.data?.featureImage?.map((image) => {
                                        return (
                                            <img className=' pl-5' src={image?.url} alt={image?.alternativeText} />
                                        )
                                    })
                                }
                            </div>
                        </> :
                        <div className="col-12">
                            <ReactMarkdown className='text-center features'>{data?.data?.features?.data?.features}</ReactMarkdown>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default Details