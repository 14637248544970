import React from 'react'
import ModalVideo from 'react-modal-video'
import { Link } from 'gatsby'
import Banner from '../Common/Banner'

const ProductsIntro = ({video, intro, bannerDetail}) => {

    // Popup Video
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>

            <ModalVideo
                channel='youtube'
                isOpen={!isOpen}
                videoId={video}
                onClose={() => setIsOpen(!isOpen)}
            />

            <section className='isbt-container intro-section'>
                <div className='isbt-intro container'>
                <div className='intro-container mb-1'>
                        <h1 className='text-center font-outfit'>{intro?.heading1}</h1>
                        <p>{intro?.description1}</p>
                    </div>
                    <Banner video={video} bannerDetail={bannerDetail} />
                    {
                        (intro?.heading3 && intro?.description3) ? (
                            <>
                            <div className='intro-container'>
                                <h2 className='text-center font-outfit'>{intro?.heading2}</h2>
                                <p>{intro?.description2}</p>
                            </div>
                            <div className='intro-container my-2'>
                                <h2 className='text-center font-outfit'>{intro?.heading3}</h2>
                                <p>{intro?.description3}</p>
                            </div>
                            </>
                        ) : (
                            <div className='intro-container my-2'>
                                <h2 className='text-center font-outfit'>{intro?.heading2}</h2>
                                <p>{intro?.description2}</p>
                            </div>
                        )
                    }
                    <div className='banner-item-content normal-box bg-white'>
                <div className='btn-box'>
                    <Link to="/demo" className="default-btn">
                        <i className="flaticon-right"></i>
                        Request a Demo
                    </Link>
                    {video ?
                        <Link
                            to="#play-video"
                            onClick={e => { e.preventDefault(); openModal() }}
                            className="video-btn popup-youtube"
                        >
                            <i className="flaticon-google-play"></i> Watch Video
                        </Link> :
                        <Link to="/history#WFM" className="default-btn deploy-btn">
                            <i className="flaticon-right"></i>
                            Successful Deployments
                        </Link>
                    }
                </div>

            </div>

                </div>

            </section>
        </>

    )
}
export default ProductsIntro;